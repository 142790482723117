<template>
  <div class="guide-dialog p-r">
    <Icon name="close" class="c-title close-icon" @click="$emit('close')" />
    <t as="p" path="quickplay_3" class="py-12 t-text f-lg t-center f-bold" style="line-height: 24px;">Guide</t>
    <div class="px-16">
      <TheCopyGuideContent :s="s" style="min-height: 200px;" />
    </div>
    <div class="d-f f-lg f-bold">
      <button class="flex-1 btn block button primary-thin" :class="{ tip: s === 1 }"
        @click="preStep"
      >
        <t path="copytrading_20" #="{td}" custom>{{ td || 'Previous' }}</t>
      </button>
      <button class="flex-1 btn block primary button"
        @click="nextStep"
      >
        <t v-if="s === total" path="copytrading_22" #="{td}" custom>{{ td || 'OK' }}</t>
        <t v-else path="copytrading_21" #="{td}" custom>{{ td || 'Next' }}</t>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent, ref } from 'vue'
import TheCopyGuideContent from '@/modules/copyTrade/components/TheCopyGuideContent.vue'

export default defineComponent({
  name: 'TheCopyGuideDialog',
  components: { TheCopyGuideContent, Icon },
  setup (props, ctx) {
    const s = ref(1)

    const total = 5

    const preStep = () => {
      s.value = s.value === 1 ? 1 : s.value - 1
    }

    const nextStep = () => {
      s.value = s.value + 1
      if (s.value > total) {
        ctx.emit('close')
      }
    }
    return {
      s,
      total,
      nextStep,
      preStep,
    }
  },
})
</script>

<style scoped>
.guide-dialog {
  width: 80%;
  border-radius: 4px;
  overflow: hidden;
  background: var(--color-theme);
}

.close-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  background: var(--color-grey);
  border-radius: 12px
}

.button {
  border-radius: 0;
  transform: none;
}

.primary-thin {
  background: var(--color-primary-thin);
  color: var(--color-primary);
}

.tip {
  background: var(--color-background);
  color: var(--color-tip);
  opacity: 1;
}

</style>
