<template>
  <div>
    <div class="df-middle py-6 px-16 c-title f-md bg-background" v-if="curRatio>0">
      <div class="flex-1">
        <p class="f-nm c-text f-bold" style="line-height: 24px;">{{ curRatio*10*10 }}%
          <t path="copytrading_12"></t>
        </p>
        <t as="p" path="copytrading_13" style="line-height: 18px;">Maximum single position</t>
      </div>
      <div class="df-middle" @click="edit">
        <t path="copytrading_14">Edit</t>
        <Icon name="list" sm />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onActivated, shallowRef, watch } from 'vue'
import Icon from '@/components/Icon.vue'
import { openDialog } from '@/components/popup/popup'
import SetMaximumDialog from '@/modules/copyTrade/components/SetMaximumDialog.vue'
import { apiUserRatioInfo } from '@/modules/copyTrade/copyTrade.api'

export default defineComponent({
  name: 'TheMaximum',
  components: { Icon },
  props: {
    freshStatus: {
      type: Number,
    },
  },
  setup (props) {
    const tbList = shallowRef<(string|number)[]>([])
    const curRatio = shallowRef(0)
    const getRatioInfo = () => {
      apiUserRatioInfo().then((res) => {
        tbList.value = res.tbList
        curRatio.value = res.positionRatio ? +res.positionRatio * 1 : 0
      })
    }
    watch(() => props.freshStatus, () => {
      getRatioInfo()
    })

    onActivated(getRatioInfo)

    const edit = () => {
      const arr = tbList.value.map((item) => {
        return +item * 10 * 10
      })
      openDialog(SetMaximumDialog, {
        radioArr: arr,
        curruntRadio: String(curRatio.value),
        onConfirm: (val: number) => {
          curRatio.value = val
        },
      })
    }
    return {
      curRatio,
      edit,
    }
  },
})
</script>
