<template>
  <DialogTemplate>
    <template #title>
      <t path="trader_17">Maximum single position</t>
    </template>
    <div class="m-16">
      <t as="p" path="trader_18" class="c-title f-md">
        For the copy trade, you need to specify maximum position for a single copy order.
      </t>
      <div class="pt-16">
        <Radio v-for="item in list" :key="item"
          class="df-middle py-8"
          v-model="maximumType" :value="item" name="maximum" @click="select(item)"
        >
          <t class="ml-8" :class="{'f-bold': item === maximumType}">
            {{item}}% <t path="trader_19">of Balance</t>
          </t>
        </Radio>
      </div>
    </div>

    <template #confirm>
      <Button class="flex-1 primary" @click="submit" :progress="progress">
        <t path="trader_21" #="{td}" custom>{{ td || 'OK' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import { defineComponent, ref, shallowRef } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { apiUpdateRatio } from '@/modules/copyTrade/copyTrade.api'
import Radio from '@/components/Radio.vue'

export default defineComponent({
  name: 'SetMaximumDialog',
  components: { DialogTemplate, Button, Radio },
  props: {
    radioArr: {
      type: Object,
      require: true,
    },
    curruntRadio: {
      type: String,
    },
  },
  setup (props, ctx) {
    const list = shallowRef(props.radioArr)

    const maximumType = ref(0)
    if (props.curruntRadio) {
      maximumType.value = +props.curruntRadio * 10 * 10
    }

    const [commit, progress] = useRequest(apiUpdateRatio)

    const done = (val: number) => {
      ctx.emit('close')
      ctx.emit('confirm', String(val))
    }

    return {
      maximumType,
      list,
      select (value: number) {
        maximumType.value = value
      },
      submit () {
        if (!maximumType.value) {
          return false
        }
        commit({
          positionRatio: String(maximumType.value / 10 / 10),
        }).then(() => done(maximumType.value / 10 / 10))
          .catch()
      },
      progress,
    }
  },
})
</script>

<style scoped lang="scss">

</style>
