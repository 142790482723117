<template>
  <DialogTemplate>
    <template #title>
      <t path="trader_26">Copy trader</t>
    </template>
    <t as="p" path="trader_27" :args="{trader: trader.name}" class="c-text t-center mx-32" style="line-height: 24px;">
      Are you sure to start copying trades from {{ trader.name }}?
    </t>
    <template #confirm>
      <Button class="flex-1 primary" @click="submit">
        <t path="trader_29" #="{td}" custom>{{ td || 'Yes' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'StopCopyDialog',
  components: { DialogTemplate, Button },
  props: {
    trader: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const done = () => {
      ctx.emit('close')
      ctx.emit('confirm')
    }

    return {
      submit () {
        done()
      },
    }
  },
})
</script>
