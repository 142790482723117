<template>
  <div class="d-f py-16">
    <Pic
      :src="model.avatar" fallback="/img/user/Avatar@2x.png"
      width="40" height="40" style="border-radius: 50%;"
    />
    <div class="ml-16 flex-1">
      <p class="f-bold" style="line-height: 24px;">{{ model.nikeName }}</p>
      <p class="f-md" style="line-height: 18px;">
        {{ model.synopsis }}
      </p>
      <TheLabel :labels="model.labels" />
      <div class="df-middle mt-12 f-md c-title" style="justify-content: space-between;margin-right:56px;">
        <div >
          <p
            :class="['mb-4 f-xl df-cross-center', { 'c-success': model.totalRatio>=0, 'c-danger': model.totalRatio<0 }]"
            style="line-height: 24px;">{{ model.totalRatio>0?'+'+model.totalRatio:model.totalRatio }}%
          </p>
          <t path="copytrading_5" as="p"></t>
        </div>
        <div >
          <p class="c-success mb-4 f-xl df-cross-center" style="line-height: 24px;">{{ model.copyers }}</p>
          <t path="copytrading_6" as="p"></t>
        </div>
        <div >
          <p class="c-success mb-4 f-xl df-cross-center" style="line-height: 24px;">{{ model.likes }}</p>
          <t path="copytrading_7" as="p"></t>
        </div>
      </div>
    </div>
  </div>
  <div class="d-f pb-24 f-medium">
    <router-link #="{ navigate }" custom :to="`/trade/copy/${model.traderId}`">
      <button class="btn flex-3 detail-btn sm br-sm" @click="navigate">
        <t path="copytrading_8"></t>
      </button>
    </router-link>
    <Button
      :progress="loading"
      :class="['btn flex-4 ml-8  line sm br-sm', {primary: !model.copyStatus, asst: model.copyStatus}]"
      @click="handleCopy(model.traderId, model.copyStatus)">
      <t :path="model.copyStatus?'copytrading_10':'copytrading_9'"></t>
    </Button>
  </div>
  <Line />
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from 'vue'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import TheLabel from '@/modules/copyTrade/components/TheLabel.vue'
import { useCopyTrade } from '@/modules/copyTrade/copyTrade'
import { mul } from 'essential/tools/math'
import { ITraderItem } from '@/modules/copyTrade/copyTrade.api'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'TheTradingItem',
  components: { Line, Pic, TheLabel, Button },
  emits: ['refresh'],
  props: {
    modelItem: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const model = reactive(props.modelItem)
    model.totalRatio = mul(props.modelItem.totalRatio, 100)
    const { loading, toggle } = useCopyTrade()
    watch(() => props.modelItem, () => {
      model.nikeName = props.modelItem.nikeName
      model.synopsis = props.modelItem.synopsis
      model.labels = props.modelItem.labels
      model.likes = props.modelItem.likes
      model.copyers = props.modelItem.copyers
      model.copyStatus = props.modelItem.copyStatus
      model.totalRatio = mul(props.modelItem.totalRatio, 100)
    })
    const handleCopy = () => {
      if (model === undefined) {
        return false
      }
      toggle(model as ITraderItem, () => {
        model.copyStatus = !model.copyStatus
        model.copyers = +model.copyers + (model.copyStatus ? 1 : -1)
        ctx.emit('refresh')
      })
    }
    return {
      model,
      loading,
      handleCopy,
    }
  },
})
</script>
<style scoped>
.detail-btn{
  background: var(--color-primary-thin);
  color: var(--color-primary);
}
</style>
