<template>
  <div class="guide-con t-text mb-20 f-nm">
    <div v-if="s === 1">
      <t path="copytrading_15" multiline #="{td}" custom>
        <p v-for="(p, index) in td" :key="index">{{p}}</p>
      </t>
    </div>
    <div v-else-if="s === 2">
      <Pic src="copy_trade/Guide1" class="mb-10" />
      <t as="p" path="copytrading_16">1. Browse investors and view their details</t>
    </div>
    <div v-else-if="s === 3">
      <Pic src="copy_trade/Guide2" class="mb-10" />
      <t as="p" path="copytrading_17">
        2. View investor's strategy introduction, trading performance, etc.
      </t>
    </div>
    <div v-else-if="s === 4">
      <Pic src="copy_trade/Guide3" class="mb-10" />
      <t as="p" path="copytrading_18">
        3. Confirm to follow an investor and wait for their trading actions
      </t>
    </div>
    <div v-else-if="s === 5">
      <t as="p" path="copytrading_19">
        4. You will be notified whenever there is a new trading action by the investor you are following
      </t>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheCopyGuideContent',
  components: { Pic },
  props: {
    s: {
      type: Number,
      default: 1,
    },
  },
})
</script>

<style scoped lang="scss">
  .guide-con {
    line-height: 24px;

    img {
      width: 100%;
      display: block;
    }
  }
</style>
