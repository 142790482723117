<template>
  <div class="d-f p-16" style="background: #DAF6E6;" @click="pageToInfo(firstModel.traderId)">
    <Pic
      :src="firstModel.avatar" fallback="/img/user/Avatar@2x.png"
      width="64" height="64"
      style="border-radius: 50%;"
    />
    <div class="ml-16">
      <p class="f-bold" style="line-height: 24px;">{{ firstModel.nikeName }}</p>
      <p class="f-md" style="line-height: 18px;">{{ firstModel.synopsis }}</p>
      <p class="mt-8 df-middle">
        <span :class="['f-xl', { 'c-success': firstModel.totalRatio>=0, 'c-danger': firstModel.totalRatio<0 }]" style="line-height: 20px;">{{ firstModel.totalRatio>0?'+'+firstModel.totalRatio:firstModel.totalRatio }}%</span>
        <t path="copytrading_5" class="f-md ml-12" style="line-height: 14px;">Last 7 days</t>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'
import router from '@/router'

export default defineComponent({
  name: 'TheRecommend',
  components: { Pic },
  props: {
    firstModel: {
      type: Object,
      required: true,
    },
  },
  setup () {
    //
    const pageToInfo = (id: string) => {
      router.push('/trade/copy/' + id)
    }
    return {
      pageToInfo,
    }
  },
})
</script>
