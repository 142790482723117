<template>
  <div class="list-label df-middle t-center f-xs c-title">
    <span v-for="item in labels" :key="item" class="label-item px-8 mr-8 mt-8">
      {{item}}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheLabel',
  props: {
    labels: Array,
  },
})
</script>

<style scoped lang="scss">
.list-label {
  flex-wrap: wrap;

  .label-item {
    min-width: 56px;
    height: 18px;
    line-height: 18px;
    border-radius: 8px;
    border: 1px solid rgba(213, 222, 232, 0.5);
  }
}
</style>
