<template>
  <PageWithHeader>
    <template #title>
      <t path="copytrading_2">Copy trading</t>
    </template>
    <template #right>
      <t path="copytrading_3" @click="showGuide" class="f-md guide">Guide</t>
    </template>
    <template #body>
      <div class="flex-1 df-col">
        <t #="{t}" custom>
          <WTTabs class="flex-1" :tabs="[t('copytrading_4'), t('copytrading_11')]">
              <Traders />
              <MyCopying />
          </WTTabs>
        </t>
      </div>
    </template>
  </PageWithHeader>
</template>

<script lang="ts">
import { openDialog } from '@/components/popup/popup'
import WTTabs from '@/components/Tabs.vue'
import { keymap } from '@/config'
import TheCopyGuideDialog from '@/modules/copyTrade/components/TheCopyGuideDialog.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { localGet, localSet } from 'essential/store/localStore'
import { defineComponent } from 'vue'
import Traders from '@/modules/copyTrade/Traders.vue'
import MyCopying from '@/modules/copyTrade/MyCopying.vue'

export default defineComponent({
  name: 'CopyTrade',
  components: {
    MyCopying,
    Traders,
    WTTabs,
    PageWithHeader,
  },
  setup () {
    const showGuide = () => {
      openDialog(TheCopyGuideDialog, { wrapperClass: 'center' }, { transition: 'slide-down' })
    }

    if (!localGet(keymap.guide.copyGuideDialog)) {
      showGuide()
      localSet(keymap.guide.copyGuideDialog, '1')
    }

    return {
      showGuide,
    }
  },
})
</script>

<style scoped>
.guide {
  padding: 2px 10px;
  border-radius: 12px;
  border: 1px solid #D5DEE8;
  color: #667C8D;
}
</style>
